import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getAdminToken } from "./Common";

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    return(
        <Route
        {...rest}
        render={props => {
            return getAdminToken() ? <Component {...props} />
            : <Redirect to={{ pathname: "/admin/login", state: {from: props.location} }} />
        }}
        />
    )
}

export default AdminPrivateRoute;