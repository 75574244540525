import axios from "axios";
export const baseurl = process.env.REACT_APP_COMMMON_URL;

const commonapi = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default commonapi;

// Send OTP
export const sendOTPUrl = "/login/sendOtpV1";

// Verify OTP - Gives token with other basic info
export const verifyOTPUrl = "/login/verifyOtpV1";

// Get token by email
export const getTokenByEmailUrl = "/getUserBYEmail/V1";

// Check if the user (by phone) is registered on Truegy or not (For TruKYC)
export const checkMobile = "/checkMobile/";

// Send OTP for login
export const sendOTPTruegy = "/login/sendOtp";

// Verify OTP for login
export const verifyOTPTruegy = "/login/verifyOtp";

// Check if the user (by phone) is registered on BGC users or not
export const checkMobileV1 = "/checkMobileV1";

// Callback url for court case in bgc
export const bgcCourtCaseCallback = "/bgcCourtCaseV2CallBack";

//  HR Details Verification/AddOn
export const hrverificationDetails = "/employee/hrVerification";

// BGC Users
export const bgcUsers = "/employees";

// Refresh token User Authentication 
export const userAuthentication = "/user/authenticate";

//Refresh token logout
export const userLogout = "/user/logout/";

// Delete a user with all the data which has registered for BGC
export const removeData = "/removeData/";

// This is for sending otp on phone for registration
export const sendPhoneOtpRegistration = "/user/phoneOtpSend";

// This is for verifying otp sent on phone for registration
export const verifyPhoneOtpRegistration = "/user/phoneOtpVerification";

// Get court case v2 details (This is for TruBGC)
export const getCourtCaseV2 = "/getBgcCourtCaseV2/";

// This API is used for sending otp on whatsapp for registration
export const sendWhatsappOtp = "/user/whatsApp/SendOtp";

// This API is used for verifying otp sent on whatsapp for registration
export const verifyWhatsappOtp = "/user/whatsApp/verifyOtp";