import commonapi, { userAuthentication } from '../Base/CommonConfig';
import api, { subUserAuthentication } from '../Base/Config';
import { setSession, getSubUserId } from "../Utils/Common";

const userAuthenticationToken = async () => {
    if(getSubUserId()) {
        await api.post(subUserAuthentication, {
            subUserId:getSubUserId(),
            token: localStorage.getItem("refresh-token")
        })
        .then(res => {
            if(res.data.code === 100) {
                setSession(res.data.Token);
            } else {
                window.location.replace('/logout');
            }
        })
    } else {
        await commonapi.post(userAuthentication, {
            token: localStorage.getItem("refresh-token")
        })
        .then(res => {
            if(res.data.code === 100) {
                setSession(res.data.token);
            } else {
                window.location.replace('/logout');
            }
        })
    }
}

export default userAuthenticationToken;