import axios from "axios";
export const baseurl = process.env.REACT_APP_CONFIG_URL;

const api = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default api;

// Check if any user is registred with this number or not
export const checkEmptraUserUrl = "/checkMobile/";

// Contact us from for products
export const oceanQuery = "/oceanQuery";

// Request demo call
export const oceanDemoQuery = "/oceanDemoQuery";

// Register new user on Invincible Ocean
export const registerUrl = "/registerV1";

// Registration with google works without phone number
// export const googleRegistrationUrl = "/registerV2"; // Old end point
export const googleRegistrationUrl = "/registerV3";

// Get token by email
export const getTokenByEmailUrl = "/getUsers/";

// Delete Client ID and Secret Key
export const deleteKeyUrl = "/emptra/deletekey/";

// Edit profile
export const editProfileUrl = "/emptra/editProfile";

// Add Business name and address
export const addAddressUrl = "/truLog/AddressUpdate/";

// Save transaction in database
export const saveTransactionUrl = "/emptra/saveTransaction";

// Pan Verification for individual KYC (Gives Aadhaar details and saves it in database as well)
export const panToAadhar = "/panKyc/panToAadharDetails";

// Aadhaar Verificaation for individual KYC (Matches name and number with details fetched from pan)
export const aadhaarVerification = "/aadharKyc/individualAadhar";

// Reset Individual KYC
export const resetUserKyc = "/resetUserKyc/";

// Get CIN number from pan in company KYC
export const getCinFromPan = "/companyKyc/getCINV1";

// Save company info for company KYC
export const getCompanyDetailsFromCin = "/companyDetails/byCINV1";

// Fetch director list for company KYC
export const getDirectorListFromCin = "/directorDetails/byCINV1";

// Pan verification of director for company KYC (Gives Aadhaar details and saves it in database as well)
export const directorPanToAadhar = "/panKyc/companyDirector";

// Aadhaar Verificaation of director for company KYC (Matches name and number with details fetched from pan)
export const directorAadhaarVerification = "/aadharKyc/companyDirector";

// Add GST for company KYC
export const addGstForCompany = "/companyGstDetails";

// Add use case for using the product
export const insertUseCase = "/kyc/insertUseCase";

// Reset company KYC
export const resetCompanyKyc = "/resetCompanyKyc/";

// Dont have GST number
export const iDontHaveGst = "/iDontHaveGST";

//Verify Aadhaar KYC Individual
export const verifyAadhaarKyc = "/invincible/AadhaarVerify" ;

//Verify Director Aadhaar KYC
export const verifyDirectorAadhaarKyc = "/invincible/companyAadhaarVerify" 

// Verify Aadhaar OTP for company KYC
export const verifyCompanyAadhaarOtp = "/directorAadhar/submitOtp";

// Update customer id
export const updateCustomerId = "/update/customerId";

// ==> Court Case Start
// Fetch list of normal court case verifications
export const fetchNormalCourtCaseList = "/getEmptraCourtCaseV1ByUserId/";

// Fetch details of normal court case verification by request id
export const fetchNormalCourtCaseDetails = "/getEmptraCourtCaseV1/";

// Fetch list of immediate and delay court case verifications
export const fetchCourtCaseList = "/getEmptraCourtCaseByUserId/";

// Fetch details of court case verification by request id
export const fetchCourtCaseDetails = "/getEmptraCourtCase/";

// ==> Court Case End

// Decrypt Background Check Code
export const decryptBGCUrl = "/decryptUserId";

// Update Background Check Request
export const updateBGCRequestUrl = "/bgcClaimUpdate";

// Aadhaar Digilocker
export const aadhaarDigilocker = "/OceanBgcAuthorize";

// Fetch Background Check Request
export const getBGCRequestUrl = "/getBgcClaim/";

// Submit Uploaded Documents for BGC
export const submitDocumentUpload = "/bgcDocumentShared";

// Resend Background Check Request
export const resendBGCRequest = "/resendEmptraBgcClaim";

// AML APIs
export const amlVerification = "/amlVerification";
export const amlVerificationData = "/getAmlVerificationData/";
export const getAmlVerification = "/getAmlVerification/";

// Get aadhaar details synced with digilocker
export const getEadhaar = "/getEadhaar";

// Get Billing List
export const getBillingList = "/getBillingList/";

// UnBilled Amount List
export const getunbilledamount = "/getunbilledamount/";

// Pay bill
export const payBill = "/updatePostPaid";

// User Accounts API users with credit amount
export const usersListWithAmount = "/allUsersWithAmount";

// Admin
export const getAllTransactions = "/getAllTransactions";

// UserAccounts(Userlist)
export const getUsersListUrl = "/emptra/userList";

// Admin login
export const adminLoginUrl = "/truLog/adminAuth";

// User Account Filter API
export const filterUsers = "/search/userFilterData/?";

// Search company
export const companySearch = "/bgc/companySearch/";

// API Usage Pagination
export const APIUsagePagination = "/getUserApiTransaction/?";

// API Usage Product transactions
export const productPagination = "/getUserApiTransaction/?";

// Fetch list of all userid and email
export const fetchUseridandEmail = "/admin/getAllTransactionsByIndex";

// Callback url for court case product
export const courtCaseCallback = "/emptra/emptraCourtCaseV2CallBack";

// Decrypt code in eign url
export const decryptEsignUrl = "/eSign/decryption";

// Fetch details of document and signer by request id
export const esignDetailsUrl = "/eSign/eSignByRequestId/";

// POST phone number and request id in payload and get signing url in response
export const aadharSignCreate = "/eSignOcean/aadharSignCreateV1";

// Append signature on the PDF with aadhaar
export const appendAadhaarSignature = "/eSignOcean/aadharSignAppendV1";

// Send otp for esign
export const sendEsignOtp = "/eSign/sendOtp";

// Verify otp for esign
export const verifyEsignOtp = "/eSign/verifyOtp";

// Sign document
export const signDocumentUrl = "/eSign/signDocument";

// Update signing status
export const updateSignStatus = "/eSign/signing";

// Send otp on email for esign
export const sendEsignEmailOtp = "/eSign/emailOtp";

// Verify otp on email for esign
export const verifyEsignEmailOtp = "/eSign/emailOtpVerify";

// Send common otp for esign
export const sendEsignCommonOtp = "/eSign/sendCommonOtp";

// Verify common otp for esign
export const verifyEsignCommonOtp = "/eSign/verifyCommonOtp";

// E-sign Responses
export const esignlistUrl = "/eSign/listByUserId/";

// E-sign Responses
export const aadhaarEsignResponses = "/getESign/byUserId/";

// // Get api list with price
// export const fetchApiPricing = "/fetchApiPricing";

// Submit landing Page Contact form
export const sendqueries = '/products/queries';

// Esign AdhaarSign Request
export const esignAadhaarRequest = '/eSign/aadharSignRequest';

// Esign Aadhaar Decryption
export const aadhaarsigndecryption = '/eSignAadhar/decryption';

// Esign Aadhar Get Request ID
export const getAadhaarrequestId = '/getESign/byRequestId/';

// Esign Base64 Api
export const getEsignBaseApi = '/getBase64forEsign';

// Append signature in pdf
export const appendSignature = '/eSign/aadharSignAppend';

export const createSignAdhaar ='/eSign/aadharSignCreate';

// Sandbox api data (get)
export const getManyApiPricingObjectId="getManyApiPricingObjectId";

// Trubank Upload File API
export const trubankFileUpload= "/emptraTruBank";

// TruBank List Data API 
export const trubanklistdata = "/truBankList/";

//  TruBank Data Using RequestId
export const trubankDetailsRequest = "/getEmptraTruBank/";

//  TruBank Data Using RequestId
export const trubankManualVerification = "/truBankManualVerification";

//TruBgv responsesList by userId (Get)
export const truBgvResponses = "/getTruBgvList/";

// Company KYC
export const directorPanKycManual ="/directorPanKycManual";

//Dashboard cashe count
export const cacheCount="/admin/casheHits/";

//Verify secondary user email
export const verifySecondaryUser="/admin/subUserVerification/";

//Subuser login
export const subuserLogin = "admin/subUsersLogin";

//Refresh token logout
export const subUserLogout ="/subUser/logout";

//Refresh token subuser Authenticate
export const subUserAuthentication="/subUser/authenticate";

//Refresh token Admin Authenticate
export const adminAuthentication ="/truLog/authenticateAdmin";

//Admin Refresh token logout
export const adminLogout ="/truLog/logOutAdmin";

//AddCCEmail
export const AddCcMail ="/remainder/amount";

// Unsunscribe API 
export const unsubscribeEmail = "/user/disableNotification";

// This is for login with email and password, also requires recaptcha token in the payload
export const emailPasswordLogin = "/userLogin/authentication";

// This is for sending otp on email for login
export const sendEmailOtp = "/userLogin/userEmailOtp";

// This is for verifying otp sent on email for login
export const verifyEmailOtp = "/userLogin/userEmailOtpVerification";

// This is for sending otp on Whatsapp for login
export const sendWhatsappOtp = "/userLogin/sendWhatsappOtp";

// This is for verifying otp sent on Whatsapp for login
export const verifyWhatsappOtp = "/userLogin/verifyWhatsappOtp";

// This is for sending otp on email for registration
export const sendEmailOtpRegistration = "/user/userEmailOtp";

// This is for verifying otp sent on email for registration
export const verifyEmailOtpRegistration = "/user/userEmailOtpVerification";

// This is for user registeration
export const userRegistration = "/user/userRegisteration";

//Send mail for forgot password
export const sendMail ="/user/mailSent/forgotPassword";

//Set new password
export const changePassword ="/users/setPassword";

//E-sign Aadhaar details from requestId
export const aadhaarEsignByRqstId = "/getESign/details/";

//E-sign Mobile details from requestId
export const mobileEsignByRqstId = "/eSign/details/";

// Get country code  from user ip for registration
export const countryCodeFromIp ="/user/getcountrycode";

// Get single Onboarding Solution Flow
export const getSingleOnboardingSolutionFlow ="/getSingleOnboardingSolutionFlow/";

// Get verification id for Onboarding Solution Flow from phone number
export const authenticateOnboardingSolutionUser ="/authenticateOnboardingSolutionUser";

// This API fetch detail of a verification flow from encrypted verification id.
export const getDetailsFromEncryption ="/getDetailsFromEncryption/";




// This API is used to Pan Name Matcher for Onboarding Solution.
export const onboardingPanNameMatcher = "/onboardingSolution/panNameMatcher";

// This API is used to IFSC Code for Onboarding Solution.
export const onboardingIfscCode = "/onboardingSolution/ifscCode";

// This function is used to verify phone KYC without OTP for Onboarding solution.
export const onboardingPhoneKycWithoutOTP = "/onboardingSolution/phoneKycWithoutOTP";

// This function is used to send OTP to verify phone KYC with OTP for Onboarding solution.
export const onboardingPhoneKycSendOTP = "/onboardingSolution/phoneKycSendOTP";

// This function is used to verify OTP to verify phone KYC with OTP for Onboarding solution.
export const onboardingPhoneKycVerifyOTP = "/onboardingSolution/phoneKycVerifyOTP";

// This function is used to send OTP to complete mobile verification for Onboarding solution.
export const onboardingMobileVerificationSendOtp = "/onboardingSolution/mobileVerificationSendOtp";

// This function is used to verify OTP to complete mobile verification for Onboarding solution.
export const onboardingMobileVerificationVerifyOtp = "/onboardingSolution/mobileVerificationVerifyOtp";

// This function is used to send OTP to complete email verification for Onboarding solution.
export const onboardingEmailVerificationSendOtp = "/onboardingSolution/emailVerificationSendOtp";

// This function is used to verify OTP to complete email verification for Onboarding solution.
export const onboardingEmailVerificationVerifyOtp = "/onboardingSolution/emailVerificationVerifyOtp";

// This function is used to verify Driving licence for Onboarding solution.
export const onboardingDrivingLicenceVerification = "/onboardingSolution/drivingLicenceVerification";

// This function is used for vehicle verification in Onboarding solution.
export const onboardingVehicleVerification = "/onboardingSolution/vehicleVerification";

// This function is used to check challan in Onboarding solution.
export const onboardingVehicleChallanVerify = "/onboardingSolution/vehicleChallanVerify";

// This function is used to check vehicle's Fastag details in Onboarding solution.
export const onboardingFastTagCheck = "/onboardingSolution/fastTagCheck";

// This function is used to send OTP to complete Aadhaar verification in Onboarding solution.
export const onboardingAadhaarVerificationSendOtp = "/onboardingSolution/sendAadhaarOtp";

// This function is used to verify OTP to complete Aadhaar verification in Onboarding solution.
export const onboardingAadhaarVerificationVerifyOtp = "/onboardingSolution/verifyAadhaarOtp";

// This function is used to verify voter id verification in Onboarding solution.
export const onboardingVoterIdVerification = "/onboardingSolution/voterIdVerification";

// This function is used to verify passport verification in Onboarding solution.
export const onboardingPassportVerification = "/onboardingSolution/passportVerification";

// This function is used to verify passport verification in Onboarding solution.
export const onboardingElectricityBillVerification = "/onboardingSolution/electricityBillVerification";

// This function is used to verify CIN verification in Onboarding solution.
export const onboardingCinVerification = "/onboardingSolution/cinVerification";

// This function is used to verify Gst basic verification in Onboarding solution.
export const onboardingGstBasicVerification = "/onboardingSolution/gstBasicSearch";

// This function is used to verify Gst detail verification in Onboarding solution.
export const onboardingGstDetailVerification = "/onboardingSolution/gstDetailsSearch";

// This function is used to verify DIN verification in Onboarding solution.
export const onboardingDinVerification = "/onboardingSolution/dinCheck";

// This function is used to verify EPFO by UAN verification in Onboarding solution.
export const onboardingEpfoByUanVerification = "/onboardingSolution/epfoUanSearch";

// This function is used to verify EPFO by phone verification in Onboarding solution.
export const onboardingEpfoByPhoneVerification = "/onboardingSolution/epfoByPhoneNo";

// This function is used to verify EPFO by phone verification in Onboarding solution.
export const onboardingCreditScoreVerification = "/onboardingSolution/creditScore";

// This function is used to verify Aadhaar without OTP verification in Onboarding solution.
export const onboardingAadhaarWithoutOtpVerification = "/onboardingSolution/aadhaarWithoutOTP";

// This function is used to verify Bank account verification in Onboarding solution.
export const onboardingBankAccountVerification = "/onboardingSolution/bankAccountVerification";

// This function is used to verify PAN verification in Onboarding solution.
export const onboardingPanVerification = "/onboardingSolution/panVerification";

// This function is used to verify PAN verification  Aadhaar in Onboarding solution.
export const onboardingPanAadhaarVerification = "/onboardingSolution/panVerificationAadhar";

// This function is used to verify AML verification in Onboarding solution.
export const onboardingAMlVerification = "/onboardingSolution/aml";

// This function is used to send OTP for Aadhaar with face match verification in Onboarding solution.
export const onboardingAadhaarFaceMatchSendOtp = "/onboardingSolution/aadhaarWithFaceMatchSendOtp";

// This function is used to verify OTP for Aadhaar with face match verification in Onboarding solution.
export const onboardingAadhaarFaceMatchVerifyOtp = "/onboardingSolution/aadhaarWithFaceMatchVerifyOtp";

// This function is used to verify Aadhaar with face match verification in Onboarding solution.
export const onboardingAadhaarFaceMatchVerification = "/onboardingSolution/aadhaarWithFaceMatch";

// This function is used to verify court case v1 verification in Onboarding solution.
export const onboardingCourtCaseV1Verification= "/onboardingSolution/courtCaseCheckV1";

// This function is used to verify court case v2 verification in Onboarding solution.
export const onboardingCourtCaseV2Verification= "/onboardingSolution/courtCaseCheckV2";

// This function is used to verify court case v3 verification in Onboarding solution.
export const onboardingCourtCaseV3Verification= "/onboardingSolution/courtCaseCheckV3";

// This function is used to fetch court case v2 and v3 verification in Onboarding solution.
export const onboardingFetchCourtCaseVerification= "/onboardingSolution/fetchCourtCaseCheck/";

// This function is used to verify Physical Address verification in Onboarding solution.
export const onboardingPhysicalAddressVerification= "/onboardingSolution/permanentAddress";

// This function is used to verify Physical Address verification in Onboarding solution.
export const onboardingOfficeAddressVerification= "/onboardingSolution/officeAddress";

// This function is used to verify Current Address verification in Onboarding solution.
export const onboardingCurrentAddressVerification= "/onboardingSolution/currentAddress";

// This function is used to verify ITR verification in Onboarding solution.
export const onboardingItrVerification= "/onboardingSolution/itrVerification";

// Onboarding Get Theme Data
export const onboardingGetThemeData = "onboardingSolution/theme/"