import React from "react";
import { Redirect, Route } from "react-router-dom";

const AgentPublicRoute = ({ component: Component, ...rest }) => {
    const token = sessionStorage.getItem('agent-token')
    return(
        <Route
        {...rest}
        render={props => {
            return !token ? <Component {...props} />
            : <Redirect to={{ pathname: "/agent/video-kyc-template"}} />
        }}
        />
    )
}

export default AgentPublicRoute;