import React, { createContext, useEffect, useState } from 'react';
import tokenapi, { getCompanyInfo, fetchKYCStatus } from '../Base/TokenConfig';
import { getUserId } from '../Utils/Common';

export const KycContext = createContext();

export const KycProvider = ({ children }) => {
    const userId = getUserId();
    const [individualStep, setIndividualStep] = useState("");
    const [individualAadhaar, setIndividualAadhaar] = useState("");
    const [companyStep, setCompanyStep] = useState("");
    const [companyAadhaar, setCompanyAadhaar] = useState("");
    const [individualResult, setIndividualResult] = useState("");
    const [companyResult, setCompanyResult] = useState("");

    useEffect(() => {
        if(userId) {
            fetchKYCDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchKYCDetails = () => {
        tokenapi.get(fetchKYCStatus + userId)
        .then((res) => {
            if(res.data.code === 100){
                if(res.data.result.kycDetails === null){
                    setIndividualStep('IndividualKYC2');
                    companyInfo();
                } else {
                    setIndividualResult(res.data.result);
                    if(res.data.result.aadharStatus){
                        if(res.data.result.gstDetails === ''){
                            setIndividualStep('IndividualKYC3');
                        } else {
                            if(res.data.result.useCase === null || res.data.result.useCase === undefined) {
                                setIndividualStep('kyc-complete');
                            } else {
                                if(res.data.result.useCase === "") {
                                    setIndividualStep('use-case');
                                } else {
                                    if(res.data.result.approvalStatus === 'Pending') {
                                        setIndividualStep('approval-pending');
                                    } else if(res.data.result.approvalStatus === 'Approved') {
                                        setIndividualStep('kyc-complete');
                                    } else if(res.data.result.approvalStatus === 'Rejected') {
                                        setIndividualStep('approval-rejected');
                                    }
                                }
                            }
                        }
                    } else {
                        setIndividualStep('IndividualKYC2');
                        setIndividualAadhaar(res.data.result.kycDetails.AADHAR_NUM);
                    }
                }
            } else {
                companyInfo();
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const companyInfo = () => {
        tokenapi.get(getCompanyInfo + userId)
        .then((res) => {
            if(res.data.code === 100){
                if(res.data.companyDetails){
                    setCompanyResult(res.data.companyDetails);
                    if(res.data.companyDetails.directorData){
                        if(res.data.directorDetails){
                            if(res.data.directorDetails.aadharStatus){
                                if(res.data.companyDetails.gstDetails === ''){
                                    setCompanyStep('CompanyKYC6');
                                } else {
                                    if(res.data.companyDetails.useCase === null || res.data.companyDetails.useCase === undefined) {
                                        setCompanyStep('kyc-complete');
                                    } else {
                                        if(res.data.companyDetails.useCase === "") {
                                            setCompanyStep('use-case');
                                        } else {
                                            if(res.data.companyDetails.approvalStatus === 'Pending') {
                                                setCompanyStep('approval-pending');
                                            } else if(res.data.companyDetails.approvalStatus === 'Approved') {
                                                setCompanyStep('kyc-complete');
                                            } else if(res.data.companyDetails.approvalStatus === 'Rejected') {
                                                setCompanyStep('approval-rejected');
                                            }
                                        }
                                    }
                                }
                            } else {
                                setCompanyAadhaar(res.data.directorDetails.kycDetails.AADHAR_NUM);
                                setCompanyStep('CompanyKYC5');
                            }
                        } else {
                            setCompanyStep('CompanyKYC4');
                        }
                    } else {
                        setCompanyStep('CompanyKYC3');
                    }
                }
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return (
        <KycContext.Provider value={{ individualStep, companyStep, individualAadhaar, companyAadhaar, individualResult, companyResult, companyInfo, fetchKYCDetails }}>
            {children}
        </KycContext.Provider>
    );
};
